.menu-container .card {
    background-color: #F2F2F3 !important;
}

.projecttitle {
    background-color: #f2e7e9 !important;
    border-radius: 9px !important;
    width: 100%;
    padding: 8px 10px !important;
    text-align: left !important;
}

.projecttitle:focus {
    box-shadow: none !important;
}

.project-select-txt {
    text-align: left;
    font-size: 0.7rem;
    font-weight: 600;
    font-family: "Vodafone";
    color: #90828d;
}

.project-select-txt:hover,
.project-select-txt:focus {
    text-align: left;
    font-size: 0.7rem;
    font-weight: 600;
    font-family: "Vodafone";
    color: #90828d;
}

.sidebarmenuicon {
    font-size: 0.7rem;
    font-weight: 600;
    color: #E60000;
}

.sidebarProjectTitle {
    text-align: left;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Vodafone";
    color: #E60000;
    text-transform: uppercase;
    margin-left: 5px;
}

.sidebar-headers {
    text-align: left;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Vodafone";
    letter-spacing: 0px;
    color: #25282B;
    text-transform: uppercase;
    cursor: pointer;

}

.projectsubtitle {
    border-radius: 9px !important;
    width: 100%;
    padding: 8px 10px !important;
    text-align: left !important;
}

.projectsubtitle:focus {
    box-shadow: none !important;
}

.tabs .active {
    text-align: left;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Vodafone";
    letter-spacing: 0px;
    color: #E60000;
    text-transform: uppercase;
}

.submenu-dropdown {
    text-align: left;
    font-size: 0.8rem;
    font-weight: 600;
    font-family: "Vodafone";
    letter-spacing: 0px;
    color: #E60000;
    text-transform: uppercase;
}

.submenu-dropdown:hover,
.submenu-dropdown:focus {
    text-decoration: none;
    color: #E60000;
}

.submenu-dropdown.active .projectsubtitle {
    background: #f2e7e9;
}

.submenu-dropdown .sidebarmenuicon {
    visibility: hidden;
}

.submenu-dropdown.active .sidebarmenuicon {
    visibility: visible;
}

.submenu-dropdown img {
    padding: 1px;
}

.submenu-dropdown.active img {
    filter: invert(13%) sepia(100%) saturate(6068%) hue-rotate(353deg) brightness(98%) contrast(89%);
}