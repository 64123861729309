@font-face {
  font-family: 'Anton';
  src: url('../public/assets/fonts/Anton/Anton-Regular.ttf');
  src: local('Anton-Regular'), local('Anton-Regular'),
    url('../public/assets/fonts/Anton/Anton-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'lato-regular';
  src: url('../public/assets/fonts/Lato/Lato-Regular.ttf');
  src: local('lato-regular'), local('lato-regular'),
    url('../public/assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'lato-bold';
  src: url('../public/assets/fonts/Lato/Lato-Bold.ttf');
  src: local('lato-bold'), local('lato-bold'),
    url('../public/assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'vodafone';
  src: url('../public/assets/fonts/Vodafone/Vodafone.ttf');
  src: local('vodafone'), local('vodafone'),
    url('../public/assets/fonts/Vodafone/Vodafone.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'vodafone-bold';
  src: url('../public/assets/fonts/Vodafone/Vodafone-Bold.ttf');
  src: local('vodafone-bold'), local('vodafone-bold'),
    url('../public/assets/fonts/Vodafone/Vodafone-Bold.ttf') format('truetype');
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
.row,
p,
h3,
h2 {
  margin: 0;
  padding: 0;
  font-family: "Vodafone";
}

a {
  text-decoration: none;
}

/* .svgLoader {
  animation: spin 0.5s linear infinite;
  margin: auto;
}

.divLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
} */

input:focus,
select:focus,
textarea:focus,
button:focus,
a:focus {
  outline: none !important;
  box-shadow: none;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
  border: none;
}

.form-control:focus,
.form-control:active {
  outline: none !important;
  box-shadow: none !important;
}

.form-control.is-invalid {
  background-image: none;
}

.form-group .input-icon i {
  color: #b3b3bd;
}

.fs-13 {
  font-size: 13px;
}

.vh-100 {
  height: 100vh;
}

.vw-100 {
  width: 100vw;
}

.h-80 {
  height: 80%;
}

.form-control {
  padding: 8px;
}

.overflow-hidden {
  overflow: hidden;
}

.validate-text {
  font-size: 0.75rem;
}

.centeredplace {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nohover {
  background: none;
}

.container-bg {
  background: #F2F2F3;
  border: 0.5px solid #25282B33;
}

.signup_btn {
  width: 148px;
  height: 34px;
  background: #25282B;
  text-align: center;
  color: #ffffff;
  opacity: 1;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-family: "Vodafone";
  border: none;
}

.signup_btn:hover {
  border: none;
  background: #3d4248;
}

.signup_btn:focus {
  border: none;
}

.input-h {
  background: transparent;
  font-size: 14px;
  /* height: 38px; */
}

.input-h:focus {
  background: transparent;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 1px;
  margin: 0px 0px 0px 8px;
}

.spinner-border-md {
  width: 2.5rem;
  height: 2.5rem;
  border-width: 1px;
  margin: 0px 0px 0px 8px;
}

.spinner-border-light {
  color: #E60000;
}

.img-heading {
  top: 6%;
  left: 15%;
  width: 55%;
  text-align: left;
  letter-spacing: 1px;
  color: #ffffff;
  text-shadow: 0px 1px 0px #00000029;
  text-transform: capitalize;
  opacity: 1;
  position: absolute;
  font-family: "Vodafone-bold";
  font-size: 40px;
  line-height: 50px;
}

.img-subheading {
  bottom: 15%;
  left: 15%;
  width: 70%;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 1px 0px #00000029;
  opacity: 1;
  position: absolute;
  font-family: "Vodafone";
  font-size: 0.9rem;
  font-weight: normal;
}

.sticky-bottom-relative {
  position: relative;
}

.account-heading {
  text-align: left;
  color: #25282B;
  opacity: 1;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Vodafone";
}

.footer-container {
  background-color: #f4f4f4;
  width: 100%;
  z-index: 9;
}

.footer-line {
  background-color: #AEADAD;
}

.login-subheading a {
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Vodafone";
  color: #E60000;
  opacity: 1;
  text-decoration: none;
}

.login-subheading a:hover {
  color: #ef9699;
  text-decoration: none;
}

.login-subheading .btn-link {
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Vodafone";
  color: #E60000;
  opacity: 1;
  text-decoration: none;
}

.login-subheading .btn-link:hover {
  color: #ef9699;
  text-decoration: none;
}

.login-subheading a:focus {
  color: #E60000;
}

.footer-list {
  list-style: none;
}

.footer-list li {
  display: inline;
  text-align: left;
  font-size: 0.7rem;
  font-weight: 600;
  font-family: "Vodafone";
  color: #25282B;
  opacity: 1;
}

.footer-list li a {
  color: #25282B;
}

.footer-list li::after {
  content: "• ";
  font-size: 6px;
  margin: 0 7px;
  top: -2px;
  position: relative;
}

.footer-list li:last-child:after {
  content: none;
}

.footer-copyright {
  text-align: left;
  font-size: 0.663rem;
  font-weight: 500;
  font-family: "Vodafone";
  color: #596068;
  /* letter-spacing: 0.5px; */
}

.footer-copyright ul {
  list-style: none;
}

.footer-copyright ul li {
  text-align: left;
  font-size: 0.663rem;
  font-weight: 500;
  font-family: "Vodafone";
  color: #596068;
  display: inline-block;
}


.footer-copyright li::after {
  content: "• ";
  font-size: 6px;
  margin: 0 7px;
  top: -2px;
  position: relative;
}

.footer-copyright ul li a {
  color: #596068;
}

.footer-copyright li:last-child:after {
  content: none;
}

.img-footer-container {
  background: #fff;
  padding: 10px;
  margin: 2%;
}

.main-heading {
  text-align: center;
  font-size: 1.6rem;
  font-family: "Vodafone-bold";
  color: #25282B;
  text-transform: capitalize;
  opacity: 1;
}

.subheading {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: "Vodafone";
  color: #25282B;
  opacity: 1;
}

.term-text {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: "Vodafone";
  color: #25282B;
  opacity: 1;
}

.term-text a {
  color: #E60000;
}

.term-text a:focus {
  color: #E60000;
}

.term-text a:hover {
  text-decoration: none;
  color: #ef9699;
}

.term-texts {
  text-align: left;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: "Vodafone";
  color: #25282B;
  opacity: 1;
}

.term-texts a {
  color: #25282B;
}

.term-texts a:focus {
  color: #25282B;
}

.term-texts a:hover {
  text-decoration: none;
  color: #25282B;
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
  border-radius: 0 !important;
}

.input-icon {
  position: absolute;
  right: 12px;
  top: 5px;
  z-index: 8;
}

.input-icon a {
  color: #25282B;
}

.input-icon a:hover {
  text-decoration: none;
  color: #25282B;
}

.input-icon a:focus {
  text-decoration: none;
  color: #25282B;
  outline: none;
}

@media screen and (max-width: 1157px) {
  .img-subheading {
    font-size: 0.5rem;
  }

  .img-heading {
    font-size: 1rem;
  }

  .account-heading {
    font-size: 0.8rem;
  }

  .login-subheading a {
    font-size: 0.6rem;
  }

  .footer-list li {
    font-size: 0.5rem;
    font-weight: 500;
  }

  .footer-copyright {
    font-size: 0.5rem;
    font-weight: 600;
  }
}

.input-group input {
  border-radius: 0;
}

.input-group-prepend input {
  border-radius: 0;
}

.hidden {
  visibility: hidden;
}

/* navbar */

.navbar {
  background: #25282B;
  padding: 0;
}

.navbar-dark .navbar-nav {
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  border: 0;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
  outline: 0;
}

.navbar-brand {
  border: 0;
  padding: 0;
}

.navbar-brand img {
  height: 18px;
  width: auto;
}

.navbar .profile {
  border-right: 0.1px solid #9f9f9f40;
}

.navbar .profile img {
  height: 14px;
}

.navbar .balance {
  border-right: 0.1px solid #9f9f9f40;
}

.navbar .balance img {
  height: 10px;
}

.navbar .label {
  text-align: left;
  font-size: 13px;
  font-family: "Vodafone";
  /* letter-spacing: 0.5px; */
  color: #ffffff;
  opacity: 0.6;
  text-transform: capitalize;
}

.navbar .balance-amount {
  text-align: left;
  font-size: 13px;
  font-family: "Vodafone";
  /* letter-spacing: 0.5px; */
  color: #E60000;
  opacity: 1;
}

.navbar .submenu img {
  height: auto;
  margin: 0;
  width: 12px;
}

.nav-custom {
  vertical-align: middle;
  display: inline-flex;
}

.navbar-toggler:focus {
  border: none;
}

.navbar-toggler-icon {
  width: 1.2em;
  height: 1.2em;
}

.dropdown-item {
  font-size: 12px;
}

.form-heading {
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  font-family: "Vodafone";
  color: #25282B;
}

.user_name {
  text-align: left;
  font-size: 13px;
  font-family: "Vodafone";
  /* letter-spacing: 0.5px; */
  color: #ffffff;
  opacity: 0.6;
}

.dashboard-container {
  background: #e9e9eb;
}

.auth-container {
  background: #fff;
}

.mt-60 {
  margin-top: 60px;
}

.sub-tab {
  background: #F2F2F3;
  position: fixed;
  width: 100%;
  margin-top: 34px;
  z-index: 1;
}

.sub-tab li a {
  padding: 6px 20px !important;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  font-family: "Vodafone";
  letter-spacing: 0px;
  color: #25282B;
  text-transform: uppercase;
  opacity: 1;
  border: none !important;
  border-radius: 0% !important;
  border-left: 0.1px solid #25282B33 !important;
  border-right: 0px solid transparent !important;
  /* border: none !important;
  border-left: 1p solid #25282B33 !important; */
}

.sub-tab li:last-child a {
  border-right: 0.1px solid #25282B33 !important;
}

.sub-tab li a:hover,
.sub-tab li a:focus {
  padding: 6px 20px !important;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  font-family: "Vodafone";
  letter-spacing: 0px;
  color: #25282B;
  text-transform: uppercase;
  opacity: 1;
  outline: 0;
}

.sub-tab a.active {
  background-color: #E60000 !important;
  color: #fff !important;
  border-radius: 0 !important;
  border-color: #E60000 !important;
}

.tabs-content {
  padding-top: 6%;
}

.tabs-Headeing {
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
  font-family: "Vodafone-bold";
  color: #25282B;
  text-transform: uppercase;
  opacity: 1;
}

.notice-row {
  background-color: #25282B0d;
  border-radius: 2px;
}

.notice-row p {
  color: #25282B;
  padding: 7px 15px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  /* letter-spacing: 0.5px; */
}

.notice-row button {
  width: 172px;
  height: 28px;
  background: #25282B;
  text-align: center;
  color: #ffffff;
  opacity: 1;
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Vodafone";
  border: none;
  margin: 7px 15px;
}

.notice-row img {
  margin-right: 15px !important;
}

.card-bg {
  background: #25282B0d;
  border: 0.5px solid #25282B33;
  border-radius: 2px;
  width: 100%;
  height: 150px;
  position: relative;
}

.card-bg-active {
  background: #F2F2F3;
  border: 0.5px solid #25282B33;
  border-radius: 2px;
  width: 100%;
  height: 150px;
  position: relative;
  padding: 13px 13px;
}

.limit-card {
  min-height: 125px;
}

.add-project-txt {
  text-align: left;
  font-size: 1rem;
  font-weight: 300;
  font-family: "Vodafone-bold";
  color: #9ca4ad;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.project-lowerbar {
  background: #f2e7e9;
  padding: 4px;
  margin-top: 30px;
}

.counter {
  background: #E60000;
  color: #ffff;
  font-size: 0.5rem;
  padding: 1px 5px;
  border-radius: 56%;
  font-family: "Vodafone";
  text-transform: uppercase;
}

.type-function {
  color: #25282B;
  font-size: 0.6rem;
  font-weight: 600;
  font-family: "Vodafone";
  text-transform: uppercase;
}

.project-name-heading {
  text-align: left;
  color: #25282B;
  font-size: 18px;
  font-weight: 400;
  font-family: "Vodafone";
  margin-left: 5px;
}

.project-name-subheading {
  text-align: left;
  color: #25282B;
  font-size: 0.75rem;
  font-weight: 400;
  font-family: "Vodafone";
  margin-left: 5px;
}

@media screen and (max-width: 1201px) {
  .type-function {
    font-size: 0.5rem;
  }
}

.dashboard-footer {
  display: block;
  position: relative;
  bottom: 0px;
  background: #e9e9eb;
}

.auth-container .dashboard-footer {
  background: none;
}

.sticky-bottom-fixed {
  position: absolute;
  bottom: 0;
}

.hamburger {
  background: none;
  position: absolute;
  top: -8px;
  line-height: 42px;
  color: #999;
  border: 0;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  z-index: 10000000000000;
  right: 15px;
  padding-left: 15px;
}

.cross {
  background: none;
  position: absolute;
  top: 0;
  color: #999;
  border: 0;
  font-size: 3rem;
  line-height: 42px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  right: 19px;
  padding-left: 15px;
}

.menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}

.menu {
  z-index: 1000000;
  position: relative;
  text-align: left;
}

@media screen and (max-width: 850px) {
  .hamburger {
    background: none;
    position: absolute;
    top: 7px;
    line-height: 45px;
    color: #999;
    border: 0;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    z-index: 10000000000000;
    right: 17px;
  }

  .cross {
    background: none;
    position: absolute;
    top: 7px;
    right: 17px;
    color: #999;
    border: 0;
    font-size: 3rem;
    line-height: 65px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1250px) {
  .hamburger {
    background: none;
    position: absolute;
    top: 7px;
    line-height: 45px;
    color: #999;
    border: 0;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    z-index: 10000000000000;
    right: 17px;
  }

  .cross {
    background: none;
    position: absolute;
    top: 7px;
    right: 17px;
    color: #999;
    border: 0;
    font-size: 3rem;
    line-height: 65px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
  }
}

.menu-row {
  margin-top: 33px !important;
}

.bg-grey {
  background-color: #E9E9EB;
}

.bg-white {
  background-color: #F2F2F3;
}

.no-border {
  border: none !important;
}

.menu-container {
  background-color: #F2F2F3 !important;
  z-index: 1;
}

@media (min-width: 992px) {
  .menu-container {
    position: fixed;
    top: 35px;
    left: 0;
  }

  .outlet-container {
    position: absolute;
    top: 35px;
    right: 0;
    min-height: 84vh;
  }

  .bg-white {
    min-height: 100vh;
  }
}

.outlet-container .container {
  padding: 0 2.4rem;
}

.radio-accordian[type='radio'] {
  appearance: none;
}

input[type='radio']:after {
  width: 13px;
  height: 13px;
  border-radius: 15px;
  top: 7px;
  left: 0px;
  position: relative;
  background-color: white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #EA8C90;
}

input[type='radio']:checked:after {
  width: 13px;
  height: 13px;
  border-radius: 15px;
  top: 7px;
  left: 0px;
  position: relative;
  background-color: #E60000;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid #E60000;
}

.custom-checkbox .form-check {
  padding-left: 0px;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox+label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.styled-checkbox+label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 15px;
  height: 14px;
  background: #fff;
  border: 1px solid #E60000;
}

.styled-checkbox:focus+label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked+label:before {
  background: #E60000;
}

.styled-checkbox:disabled+label {
  color: #b8b8b8;
  cursor: auto;
}

.styled-checkbox:disabled+label:before {
  box-shadow: none;
  background: #ddd;
}

.styled-checkbox:checked+label:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 6px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white,
    3px 0 0 white,
    3px -2px 0 white,
    3px -4px 0 white,
    3px -6px 0 white,
    3px -6px 0 white;
  transform: rotate(45deg);
}

.ssh-checkbox.form-check-input:checked  {
  background-color: red;
  border-color: red;
  border-radius: 0px !important;
  box-shadow: none;
}

.ssh-checkbox.form-check-input[type=checkbox] {
  color: #b8b8b8 !important;
cursor: auto;
  border-radius: 0;
  box-shadow: none;
  border: 0.5px solid red !important;

} 

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  margin: 0;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  padding: 6px 15px;
  cursor: pointer;
  background: #F2F2F3;
  color: #25282B;
  font-size: 13px;
  font-weight: normal;
  font-family: "Vodafone";
  border: 0.5px solid #25282B33;
  /* letter-spacing: 0.5px; */
}

.react-tabs__tab--selected {
  background: #E9E9EB;
  color: #E60000;
  font-size: 13px;
  font-weight: normal;
  font-family: "Vodafone";
  border-bottom: 2px solid #E60000;
  /* letter-spacing: 0.5px; */
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.page-header {
  text-align: left;
  font-size: 17px;
  font-weight: 600;
  font-family: "Vodafone";
  color: #25282B;
  text-transform: uppercase;
  /* letter-spacing: 0.5px; */
}

.add-btn {
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  font-family: "Vodafone";
  color: #fff;
  text-transform: uppercase;
  background: #E60000;
  border-radius: 2px;
  border: none;
  height: 35px;
  align-items: center;
  display: inline-flex;
}

.add-btn:hover {
  background: #E60000;
}

.searchbar {
  font-family: FontAwesome, "Vodafone";
  color: #555;
  font-size: 15.4px;
  width: 100%;
  background: #e2dfdf;
  border: none;
  padding: 0.5rem 0.6rem;
  border-radius: 4px;
  /* letter-spacing: 0.5px; */
}

.searchbar:focus {
  font-family: FontAwesome, "Vodafone";
  color: #25282B;
  font-size: 15.4px;
  width: 100%;
  background: #fff;
  border: none;
  padding: 0.5rem 0.6rem;
  border-radius: 4px;
  /* letter-spacing: 0.5px; */
}

.server-table {
  color: #25282B;
  font-size: 0.9rem;
}

.customtablerowTH {
  background: #E9E9EB;
  border: 0.5px solid #25282B33;
  border-radius: 20px;
}

.customtable>:not(caption)>*>* {
  border-top: 0.5px solid #25282B33;
  border-bottom: 0.5px solid #25282B33;
  border-bottom-width: 0px;
}

.rounded-round {
  border-top-left-radius: 10px;
}

.customtable tbody td {
  font-size: 15px;
  font-weight: 400;
  font-family: "Vodafone";
  color: #25282B;
  background-color: #F2F2F3;
  word-wrap: break-word;
  /* letter-spacing: 0.5px; */
}

.share-iicon {
  font-size: 0.9rem !important;
  color: #E60000;
  padding-right: 4px;
}

.table---header {
  text-align: left;
  font-size: 0.8rem;
  font-weight: bold;
  font-family: "Vodafone";
  color: #75767d;
  text-transform: uppercase;
}

/* table */
.customtable {
  border-collapse: separate;
  border-spacing: 0 0.4rem;
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.customtable th,
.customtable td {
  background: #eee;
  border-top: 0.5px solid #25282B33;
  border-bottom: 0.5px solid #25282B33;
  border-bottom-color: #25282B33 !important;
  padding: 10px 20px;
}

.customtable th {
  padding: 10px 20px;
  font-size: 13px;
  color: #25282B;
  opacity: 0.6;
  /* letter-spacing: 0.5px; */
}

.customtable th:first-child,
.customtable td:first-child {
  border-top-left-radius: 5px;
  border-left: 0.5px solid #25282B33;
  border-bottom-left-radius: 5px;
}

.customtable th:last-child,
.customtable td:last-child {
  border-top-right-radius: 5px;
  border-right: 0.5px solid #25282B33;
  border-bottom-right-radius: 5px;
}

.customtable th+th,
.customtable th+td,
.customtable td+th,
.customtable td+td {
  border-left: 0;
}

.customtable .count-display {
  padding: 5px 20px;
}

.pagination-row {
  border: 0.5px solid #25282B33;
  background: #E9E9EB;
  border-radius: 5px;
}

.customtable .sort {
  margin-left: 5px;
}

.pagination-txt {
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  font-family: "Vodafone";
  color: #25282B;
  /* letter-spacing: 0.5px; */
}

.selectdrop select {
  background: #fff;
  padding: 2px;
  font-size: 12px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 16px;
  padding: 2px;
}

.page {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #25282B;
  font-size: 12px;
  margin: 0 2px;
}

.directnbtn {
  color: #25282B;
  font-size: 12px;
  cursor: pointer;
}

.pages {
  display: flex;
  flex-direction: row;
}

.pages .active {
  background-color: #fff;
  color: #E60000;
}

.more-btn {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background: transparent;
  border: none;
  color: #25282B;
  font-size: 9px;
  border-radius: 50%;
  padding: 6px;
}

.more-btn:hover,
.more-btn:active,
.more-btn:focus {
  background: #E9E9EB;
  color: #25282B;
  box-shadow: none;
}

.popover-body:last-child {
  margin-top: 0;
}

.popover-body:first-child {
  margin: 0;
}

.popover-body {
  padding: 0px 0;
  color: #212529;
  text-align: left;
  font-size: 10px;
  cursor: pointer;
  display: flex;
  align-items: center !important;
  margin-top: 0;
  outline: none;
  border: none;
}

.popover-body:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.popover-body:hover {
  color: #E60000;
}

.popover-body:hover img {
  filter: invert(13%) sepia(100%) saturate(6068%) hue-rotate(353deg) brightness(98%) contrast(89%);
}

.empty-center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  height: 50%;
}

.Tab-heading {
  font-size: 26px;
  font-weight: 500;
  font-family: "Vodafone";
  color: #25282B;
}

.Tab-subheading {
  font-size: 14px;
  font-weight: 500;
  font-family: "Vodafone";
  color: #25282B;
}

a.Tab-subheading:hover{
  color: #E60000;
}

.app-table {
  display: flex;
  justify-content: end;
  align-items: center;
}

.app-table img {
  width: 10px;
}

.empty-heading {
  font-size: 6rem;
  font-weight: normal;
  color: #D5D6D9;
  margin: 10px 0;
}

.min-tabcontent {
  min-height: 81vh;
}

.form-check-label {
  font-size: 10px;
  margin-left: 5px;
}

.create-btn {
  background-color: #25282B;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  font-family: "Vodafone";
  color: #fff;
  text-transform: uppercase;
  border-radius: 2px;
  width: auto;
  height: 37px;
  border: 1px solid #25282B;
}

.create-btn:hover {
  background-color: #25282B;
  border: 1px solid #25282B;
  color: #fff;
}

.btn-cancel {
  background-color: #dfdfe2;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  font-family: "Vodafone";
  color: #25282B;
  text-transform: uppercase;
  border-radius: 2px;
  width: auto;
  height: 37px;
  border: none;
  align-items: center;
  display: flex;
}

.btn-cancel:hover {
  background-color: #dddde4;
}

.btn-other {
  background-color: #E8E8EA;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  font-family: "Vodafone";
  color: #0068E6;
  text-transform: uppercase;
  border-radius: 2px;
  width: auto;
  height: 37px;
  border: none;
}

.btn-other:hover {
  background-color: #E8E8EA;
}

.navsub-tab {
  width: 100%;
}

.navsub-tab li::selection {
  border-bottom: 2px solid #E60000;
}

.navsub-tab li:first-child {
  border-left: 0 !important;
}

.navsub-tab li:last-child {
  border-right: 0 !important;
}

.navsub-tab .nav-link {
  padding: .4rem 1.4rem;
  color: #25282B;
}

.navsub-tab .nav-link.active {
  padding: .4rem 1.4rem;
  color: #25282B;
}

.nav-link-active {
  color: #E60000 !important;
}

.navsub-tab .nav-item .active {
  font-size: 12px;
  font-weight: bold;
  font-family: "Vodafone";
  color: #E60000;
  background-color: #E9E9EB;
  border-bottom: 2px solid #E60000;
  border-top: 0 !important;
}

.settings-tab {
  background: #dfdfe2;
  width: 100%;
}

.amount-txt {
  text-align: left;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: "Vodafone";
  color: #E60000;
}

.font-1 {
  font-size: 0.9rem !important;
}

.popover {
  padding: 0;
  max-width: 145px;
}

.popup-view button,
.popup-view a {
  padding: 10px;
}

.password-instruction label {
  color: #25282B;
  opacity: 0.7;
  font-size: 13px;
  font-family: "Vodafone";
}

.password-instruction ul {
  padding-left: 20px;
}

.password-instruction ul li {
  color: #25282B;
  opacity: 0.7;
  font-size: 13px;
  font-family: "Vodafone";
  margin: 0 0 5px 0;
}

.loader-dots {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #25282B;
  margin: 0 1.8px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

.spinner-starting {
  color: green;
  width: 12px;
  height: 12px;
  margin: 0px;
}

.spinner-stopping {
  color: red;
  width: 12px;
  height: 12px;
  margin: 0px;
}

.spinner-Detaching {
  color: #FDB91E;
  width: 12px;
  height: 12px;
  margin: 0px;
}

.spinner-rebooting {
  color: blue;
  width: 12px;
  height: 12px;
  margin: 0px;
}

.spinner-restoring {
  color: #FFB100;
  width: 12px;
  height: 12px;
  margin: 0px;
}

.amount-label {
  color: #E60000;
  font-size: 15px;
}

.vh7 {
  height: 7vh;
}

.operationsystem-header {
  color: #25282B;
  font-size: 15px;
  font-weight: 400;
  font-family: "Vodafone";
  /* letter-spacing: 0.5px; */
}

.operationsystem-subheader {
  color: #b7b1b6;
  font-size: 13px;
  font-weight: 700;
  font-family: "Vodafone";
  line-height: normal;
  margin-top: 5px;
  /* letter-spacing: 0.5px; */
}

.btn-dots {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
}

.fs-15 {
  font-size: 15px;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #E60000;
}

.dropdown-item.active img,
.dropdown-item:active img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(32deg) brightness(102%) contrast(105%);
}

.confirm-form p {
  color: #25282B;
  font-size: 15px;
  font-weight: normal;
  font-family: "Vodafone";
}

.popup-view img {
  margin-right: 5px;
}

.console-content {
  padding-top: 34px;
}

.bill-details {
  background: #E9E9EB;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.amount-details-sub {
  font-size: 14px;
  color: #25282B;
  font-weight: normal;
}

.amount-details-active-sub {
  color: #E60000;
}

.loading-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  text-align: center;
}

.loader-container {
  width: 100%;
  height: 75vh;
  position: relative;
  z-index: 1;
}

.loader-container-sm {
  width: 100%;
  height: 25vh;
  position: relative;
  z-index: 1;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  text-align: center;
}

.empty-page img {
  width: 100px;
  height: auto;
}


.vertical-center {
  /* Sizing */
  width: 100vw;
  height: 100vh;

  /* Flexbox stuff */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Text styles */
  text-align: center;
  color: white;
  /* ADD THIS LINE */
}

.vertical-center .img-success {
  width: 40%;
}

.hero-inner {
  width: 100%;
}

.vertical-center h1 {
  color: #031226;
}

.vertical-center p {
  color: #25282B;
  font-size: 14px;
  font-weight: normal;
}

.card-plan {
  background: #F2F2F3;
  border-radius: 4px;
  border: 0.5px solid #25282B33;
}

.card-plan-header {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  font-family: "Vodafone";
  color: #25282B;
  /* margin: 0px 10px; */
}

.card-plan-header small:first-child {
  font-size: 14px;
  position: relative;
  margin-right: 3px;
  margin-left: 0;
}

.card-plan-header small {
  font-size: 15px;
  margin-left: 3px;
  margin-right: 0px;
}

.card-plan-header-active {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  font-family: "Vodafone";
  color: #E60000;
  /* margin: 0px 10px; */
}

.card-plan-header-active small:first-child {
  font-size: 14px;
  position: relative;
  margin-right: 3px;
  margin-left: 0;
}

.card-plan-header-active small {
  font-size: 15px;
  margin-left: 3px;
  margin-right: 0px;
}

.card-plan-subheader {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  font-family: "Vodafone";
  color: #25282B;
}

.card-body-plan {
  background: transparent;
  border: 0.5px solid #7F899533;
  color: #75767d;
  border-radius: 2px;
  font-size: 0.8rem;
  font-family: "Vodafone";
}

.card-body-plan-active {
  background: #E600000D;
  border: 0.5px solid #E600000D;
  color: #75767d;
  border-radius: 2px;
  font-size: 0.8rem;
  font-family: "Vodafone";
}

.radio-accordian-plan-card[type='radio'] {
  appearance: none;
}

.radio-accordian-plan-card[type='radio']:checked {
  appearance: none;
}

.radio-accordian-plan-card[type='radio']::after {
  top: 0px;
}

.radio-accordian-plan-card[type='radio']:checked::after {
  top: 0px;
}

.pr-resource label {
  color: #262F3A;
  opacity: 0.7;
  font-size: 13px;
  font-family: "Vodafone";
  text-align: left;
}

.pr-resource ul {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none
}

.pr-resource ul li {
  color: #262F3A;
  font-size: 15px;
  font-family: "Vodafone";
  margin: 0 0 10px 0;
  line-height: 22px;
}

.pr-resource ul li::before {
  content: "•";
  color: #636972;
  margin-right: 10px;
}

.form-switch .form-check-input {
  width: 2.4em;
  height: 1.2rem;
}

.server-images .form-check-input:checked {
  background-color: #E60000;
  border-color: #E60000;
}